@charset "utf-8";

@use "vendors";
@use "theme";
@use "functions";
@use "mixins";
@use "fonts";
@use "typography";

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
}

#root {
  display: grid;
  grid-template: auto 1fr auto / 1fr;
  min-height: 100vh;
}
