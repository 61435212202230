@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@layer base {
  body {
    font-family: Manrope, sans-serif;
  }
}
